import { DeviceDetectorService } from 'ngx-device-detector';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../core/global.service';

@Component({
  selector: 'logo-component',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.sass']
})
export class LogoComponent {
  // public g = {
  //   program: {
  //     images: {
  //       logo: 'assets/logo.png',
  //       logoMobile: 'assets/logo.png'
  //     }
  //   },
  //   type: 'special'
  // }
  device

  constructor(
    public deviceService: DeviceDetectorService,
    public router: Router,
    public g: GlobalService
  ) {
    this.device = this.deviceService.deviceType
  }

}

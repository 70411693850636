import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { merge } from 'lodash-es'
// console.log('GlobalService')
@Injectable({ providedIn: "root" })
export class GlobalService {
  path
  now = new Date()
  api

  private observables: any = {}
  private data = {};
  month: any;

  constructor(
    private route: Router
  ) {
    // TODO if no stores, select nearest one
    // this.set('user', { displayName: 'Miguel Ribeiro e Silva', stores: ['CascaisShopping', 'Oeiras Parque'] })
  }

  set(variable, value) {
    // console.log(value)
    if (typeof (value) === 'object') value = merge(this.data[variable], value)
    // console.log(value)
    this.data[variable] = value;
    if (this.observables[variable]) {
      this.observables[variable].next(this.data[variable] || null)
    }
  }

  get(variable) { return this.data[variable] }
  get all() { return this.data }
  get type() { return this.data['type'] }
  get nick() { return this.data['nick'] }
  get program() { return this.data['program'] }
  get access() { return this.data['access'] }
  get mode() { return (<any>document).location.pathname.split('/')[1] }
  get projectId() { return this.data['projectId'] }
  get apiUrl() { return this.data['apiUrl'] }
  get user() { return this.data['user'] }
  get os() { return this.data['os'] }
  get isBrowser() { return this.data['isBrowser'] || false }
  get currentPage() { return this.data['currentPage']}
  get previousRoute() { return this.data['previousRoute']}
  get currentLocation() { return this.data['currentLocation']}


  get$(variable: string): BehaviorSubject<any> {
    if (!this.observables[variable] || this.observables[variable] === undefined) {
      this.observables[variable] = new BehaviorSubject(this.data[variable] || null);
    }
    return this.observables[variable];
  }

  get canWrite() {
    const allowed = this.get('rawMenus').filter(r => this.get('profile').writeables.indexOf(r.id) > -1).map(r => r.route);
    return allowed.filter(url => this.route.url.indexOf(url) > -1).length > 0;
  }

  clear() {
    return this.data = {}
  }

  delete(variable) {
    return this.data[variable] = null;
  }

}


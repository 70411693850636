<nav class="fixed-top navbar navbar-expand-lg p-0 bg-white">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <logo-component></logo-component>
    </a>
    <!-- <span class="signature text-center">
      where marketing meets technology
    </span> -->
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
      <span class="navbar-toggler-icon"><i class="fas fa-2x fa-bars"></i></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
      <div class="navbar-nav float-end" *ngIf="pages && pages.length">
        <a class="nav-item nav-link m-1" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" (click)="goToPage(page)" *ngFor="let page of pages">
          {{page.name}}
        </a>
      </div>
    </div>

  </div>

</nav>




<div *ngIf="g.program?.stage==='preparation' && !isDev">
  <h1 class="badge is-large btn-danger">SITE EM MANUTENÇÃO. POR FAVOR, VOLTE MAIS TARDE</h1>
</div>

import { LogoModule } from './logo/logo.module';
import { TranslocoModule } from '@ngneat/transloco';
import { NavSiteComponent } from './nav.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NavSiteComponent],
  imports: [
    CommonModule,
    RouterModule ,
    LogoModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [NavSiteComponent],
})
export class NavSiteModule { }


import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as FireStoreParser from 'firestore-parser'
import { env } from '../../environments/environment'

let apiUrl = '' // env.fb.apiUrl

@Injectable({ providedIn: 'root' })
export class FirestoreApi {

  constructor(
    private http: HttpClient,
    private g: GlobalService
  ) {
    apiUrl = this.g.get('env').apiUrl
  }

  private getFieldType(value) {
    if (typeof value === 'boolean') return 'booleanValue'
    if (typeof value === 'string') return 'stringValue'
    if (!isNaN(value)) return 'integerValue'
    if (value instanceof Array) return 'arrayValue'
    return 'stringValue'
  }

  private getFieldValue(value) {
    if (value instanceof Array) return { values: value.map(v => ({ [this.getFieldType(v)]: this.getFieldValue(v) })) }
    return value
  }

  list(_path, args: any): Observable<any> {
    const collectionId = _path.split('/').pop()
    const path = args && args.group || _path.split('/').length === 1 ?
      _path :
      _path.replace(`/${collectionId}`, '')

    // console.log(_path, path, collectionId)
    const ops = {
      '==': 'EQUAL',
      '!=': 'NOT_EQUAL',
      '<': 'LESS_THAN',
      '<=': 'LESS_THAN_OR_EQUAL',
      '>': 'GREATER_THAN',
      '>=': 'GREATER_THAN_OR_EQUAL',
      'array-contains': 'ARRAY_CONTAINS',
      'in': 'IN'
    }
    const directions = {
      asc: 'ASCENDING',
      ASC: 'ASCENDING',
      desc: 'DESCENDING',
      DESC: 'DESCENDING'
    }
    const url = `${apiUrl}/${path}:runQuery?t=${new Date().getTime()}`
    const structuredQuery = { from: { collectionId } }
    if (args) {
      if (args.group) structuredQuery.from['allDescendants'] = true
      if (args.select)
        structuredQuery['select'] = { fields: args.select.map(s => ({ fieldPath: s })) }
      if (args.where)
        structuredQuery['where'] = {
          compositeFilter: {
            filters: args.where.map(w => ({
              fieldFilter: {
                field: { fieldPath: w[0] },
                op: ops[w[1]],
                value: { [this.getFieldType(w[2])]: this.getFieldValue(w[2]) }
              }
            })), op: 'AND'
          }
        }
      if (args.orderBy)
        structuredQuery['orderBy'] = {
          field: { fieldPath: args.orderBy },
          direction: args.direction ? directions[args.direction] : directions.asc
        }
      if (args.limit) structuredQuery['limit'] = +args.limit
      if (args.offset) structuredQuery['offset'] = +args.offset
    }
    // console.log(JSON.stringify(structuredQuery))
    // console.log(url, { structuredQuery })
    return this.http.post(url, { structuredQuery })
      .pipe(map((res: any[]) => res.length ? res.filter(r => r.document) : []))
      .pipe(map((res: any[]) => res.map(r => {
        if (r.document && !r.document.fields) return false
        const doc: any = FireStoreParser(r.document.fields, r.document.name || null)
        const id = r.document.name.split('/')[r.document.name.split('/').length - 1]
        return { ...doc, id }
      })), map((res: any[]) => res.filter(r => r)))
  }

  get(_path, id): Observable<any> {
    // console.log(`${apiUrl}/${_path}/${id}`)
    return this.http.get(`${apiUrl}/${_path}/${id}`)
      .pipe(
        // tap(res => console.log(res['fields'])),
        // tap(res => console.log(res['fields'] ? FireStoreParser(res['fields']) : [])),
        map((res: any) => res['fields'] ? ({ ...FireStoreParser(res['fields']), id }) : [])
      )
    // return this.http.get(`${apiUrl}/${_path}/${id}`).pipe(map(res => {
    //   const result = res['fields'] ? FireStoreParser(res['fields']) : []
    //   console.log('api-get', _path, result)
    //   return result
    // }))
  }

}

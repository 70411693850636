export const env = {
  production: true,
  pp: false,
  id: 'yes4eu',
  region: 'europe-west3',

  _yesKey: 'dGhlIG9sYXJpYSBzZXJ2aWNlIGtleQ==',

  fb: {
    apiKey: "AIzaSyCC7oxLLRLr_-ZcJIkdylb7eOERuiSKOYw",
    authDomain: "yes4eu.firebaseapp.com",
    projectId: "yes4eu",
    storageBucket: "yes4eu.appspot.com",
    messagingSenderId: "617481190061",
    appId: "1:617481190061:web:7c128ba0861a48c8c16424",
    measurementId: "G-0BB1YECESM",
  },
  apiUrl: `https://firestore.googleapis.com/v1/projects/yes4eu/databases/(default)/documents`,

  stripePublishable: 'pk_test_8WLlgoMFsLnKWW1oQG7BVHQ8',

  _cryKey: 'JpYS',

  algolia: {
    appId: '3M8JWUF0PM',
    apiKey: '5501a4a9e7f84050a292cdb5fa22bb12'
  },

  infobip: {
    url: 'https://api.infobip.com/'
  },

  _yesSalt: 'dGhlIG9sYXJpYSBzYWx0ZSBrZXkgc2VydmljZQ==',

  easyPayUrl: 'https://api.prod.easypay.pt/2.0/single',
  easyPayId: '7cb9b8de-28f6-4c87-aca0-f2afb0f733d7',
  easyPayKey: '6a32abbe-f69e-4693-a010-90605061ceb1',

  webPushKey: 'BBx_-Vl9pt1Smv8WICyNY2gplr3H8sWPak5Gu1GN5bPjxz24b9qAeYmLClo8k0J1Zm26EwFM3a-SVlcJZ_1J8So',

  // MS AD login
  msAdTenant: 'e7f9d69c-13f3-457c-a04c-f555c1134fa4',
  msAdClient: '3d67efb5-285d-401c-bd5c-081b8b56e97c',
  msRedirect: 'https://somos.ageas.pt/login/token',

  bulkSMS: {
    name: 'yes4eutests',
    url: 'https://api.bulksms.com/v1',
    auth: 'Basic NjM5RUZBNDU3NTI1NDNCNzkxNEI3MjVBMkQ0MTM2NzYtMDItQjpYdyNfKktiOVRMaTNqb0JDOFV4VmpIVXhmS0NQZg=='
  }
};

import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, Optional, PLATFORM_ID, Injector } from '@angular/core'
import { GlobalService } from './core/global.service'
import firestoreParser from 'firestore-parser'
import { env } from '../environments/environment'
import { HttpClient } from '@angular/common/http'
import { isPlatformServer } from '@angular/common'
import { TransferStateService } from './core/transfer-state.service'
import { first } from 'rxjs/operators'
import { makeStateKey } from '@angular/platform-browser'
import { LoaderService } from './core/loader.service'
import { ThemeService } from './core/theme.service'

@Injectable({ providedIn: 'root' })

export class AppInitService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject('nick') private nick,
    @Optional() @Inject('isLocal') private isLocal,
    private g: GlobalService,
    private http: HttpClient,
    private transferState: TransferStateService,
    private themeService: ThemeService,
    private loader: LoaderService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const NICK_KEY = makeStateKey<string>('NICK_KEY');
    const IS_LOCAL_KEY = makeStateKey<string>('IS_LOCAL_KEY');
    if (isPlatformBrowser(this.platformId)) { // get nick from transferState if browser side
      this.nick = this.transferState.get(NICK_KEY, 'yesmkt');
      this.isLocal = this.transferState.get(IS_LOCAL_KEY, false);
    } else { // server side: get provided nick and store in transfer state
      this.transferState.set(NICK_KEY, this.nick);
      this.transferState.set(IS_LOCAL_KEY, this.isLocal);
    }
    g.set('isBrowser', isPlatformBrowser(this.platformId))
    g.set('isServer', isPlatformServer(this.platformId))
  }

  async init(): Promise<any> {

    return new Promise<void>((resolve, reject) => {
      this.g.set('nick', this.nick)
      this.g.set('isLocal', this.isLocal)
      console.log(this.g.get('nick'), 'local:', this.g.get('isLocal'), 'browser:', this.g.get('isBrowser'), 'server:', this.g.get('isServer'))
      this.getProgram().then(program => {
        this.g.set('program', program)
        localStorage.setItem('p', JSON.stringify(program))
        this.themeService.changeTheme(program)
        resolve()
      }).catch(err => console.log(err, this.nick))

    })
  }

  getProgram() {
    return this.http.get(`${env.apiUrl}/programs/${this.g.nick}`)
      .pipe(first()).toPromise().then((res) => {
        // console.log(res['fields'])
        const program = res['fields'] ? firestoreParser(res['fields']) : null
        this.g.set('program', program)
        // console.log(this.g.get('program'), program.nick)
        // if (this.g.get('isLocal')) {
        // console.log(program.typography.fontFamilySansSerif, program.typography.fontFamilyMonospace)
        this.loader.font(program.typography.fontFamilySansSerif)
        this.loader.font(program.typography.fontFamilyMonospace)
        // }
        // else
        //   this.loader.style(program.nick, program.cssUrl)
        return program
      })
  }

}

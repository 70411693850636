
import { Injectable } from '@angular/core';
import {
  doc, getDoc, collection, getDocs, collectionGroup, Firestore, orderBy, limit,
  query, where, setDoc, updateDoc, addDoc, deleteDoc
} from '@angular/fire/firestore';
import { first, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

import { FirestoreApi } from './firestore.api'

@Injectable({ providedIn: 'root' })

export class FirestoreService {
  globals = ['programs', 'langs', 'cards', 'debug', 'movies', 'formsConfig', 'theaters', 'transactionsGateway', '__swap']
  settings = ['campaignCalendars', 'campaignTemplates', 'chains', 'storeCategories', 'storeDisplays', 'storeTypes', 'storeZones']

  constructor(
    private firestore: Firestore,
    private g: GlobalService,
    private api: FirestoreApi
  ) { }

  get createId() { return btoa(new Date().getTime().toString()).replace(new RegExp('=', 'g'), '') }
  get now() { return new Date().toISOString() }
  get updatedBy() { return this.g && this.g.get('user') ? this.g.get('user').email : '' }

  nick(collection) {
    return this.g.program && this.g.program.nicks && this.g.program.nicks[collection] ? this.g.program.nicks[collection] : this.g.nick
  }

  path(collection) {
    let path = `${collection}/${this.nick(collection)}/${collection}`
    // console.log(collection, this.globals, this.globals.includes(collection))
    if (this.globals.includes(collection)) path = collection
    if (this.settings.includes(collection)) path = `settings/${this.nick(collection)}/${collection}`
    // console.log(path)
    return path
  }

  list(_collection, args?): Observable<any[]> {
    // console.log('g.isBrowser', this.g.isBrowser)
    const path = this.path(_collection)
    if (this.g.isBrowser && (!args || !args.api)) {
      const col = collection(this.firestore, path)
      const qry = []
      if (args && args.where) for (const w of args.where) qry.push(where(w[0], w[1], w[2]))
      if (args && args.orderBy) qry.push(orderBy(args.orderBy, args.direction ? args.direction : 'asc'))
      if (args && args.limit) qry.push(limit(args.limit))
      const q = qry.length ? query(col, ...qry) : query(col)
      // console.log(collectionData(q, { idField: 'id' }))
      return new Observable(obs => {
        getDocs(q).then().then(v => {
          console.log(v.docs)
          obs.next(v.docs.map(d => ({ ...d.data(), id: d.id })))
        })
      })
    } else return this.api.list(path, args).pipe(first())
  }

  get(_collection, id, api = false): Observable<any> {
    const path = this.path(_collection)
    if (this.g.isBrowser && !api) {
      const _doc = doc(this.firestore, path, id)
      return new Observable(obs => {
        getDoc(_doc).then(value => obs.next(value.data() || null))
      })
    } else return this.api.get(path, id)
  }

  listGroup(_collection, args): Observable<any[]> {
    const col = collectionGroup(this.firestore, _collection)
    const qry = []
    if (args && args.where) for (const w of args.where) qry.push(where(w[0], w[1], w[2]))
    if (args && args.orderBy) qry.push(orderBy(args.orderBy, args.direction ? args.direction : 'asc'))
    if (args && args.limit) qry.push(limit(args.limit))
    const q = qry.length ? query(col, ...qry) : query(col)
    return new Observable(obs => {
      getDocs(q).then().then(v => {
        console.log(v.docs)
        obs.next(v.docs.map(d => ({ ...d.data(), id: d.id })))
      })
    })
  }

  getBy(_collection, field, value) {
    // const path = this.path(_collection)
    // if (this.g.isBrowser) {
    //   const _col = collection(this.firestore, path)
    //   const q = query(_col, where(field, '==', value))
    //   return collectionData(q, { idField: 'id' }).pipe(map(v => v.length ? v[0] : null))
    // } else return this.api.list(path, { where: [[field, '==', value]] }).pipe(map(v => v.length ? v[0] : null))
    return this.list(_collection, { where: [field, '==', value] }).pipe(map(v => v.length ? v[0] : null))
  }

  update(_collection, id, data): Promise<any> {
    const path = this.path(_collection)
    const _doc = doc(this.firestore, path, id)
    return updateDoc(_doc, JSON.parse(JSON.stringify(data)))
  }

  set(_collection, id, data): Promise<any> {
    const path = this.path(_collection)
    const _doc = doc(this.firestore, path, id)
    return setDoc(_doc, JSON.parse(JSON.stringify(data)))
  }

  merge(_collection, id, data): Promise<any> {
    const path = this.path(_collection)
    const _doc = doc(this.firestore, path, id)
    return setDoc(_doc, JSON.parse(JSON.stringify(data)), { merge: true })
  }

  add(_collection, data): Promise<any> {
    const path = this.path(_collection)
    if (!JSON.parse(JSON.stringify(data)).hasOwnProperty('active')) JSON.parse(JSON.stringify(data)).active = true;
    const col = collection(this.firestore, path)
    return addDoc(col, JSON.parse(JSON.stringify(data)))
  }

  delete(collection, id): Promise<any> {
    const path = this.path(collection)
    const _doc = doc(this.firestore, path, id)
    return deleteDoc(_doc)
  }

  public copy(oldPath, newPath) {
    const col = collection(this.firestore, oldPath)
    getDocs(query(col)).then(res => {
      for (const data of res.docs) {
        // console.log(this.firestore, newPath, data['id'])
        const _doc = doc(this.firestore, newPath, data['id']);
        // console.log(_doc, JSON.parse(JSON.stringify(data)))
        setDoc(_doc, JSON.parse(JSON.stringify(data)));
      }
    })
  }

}

export function getTree(list, parent = 'region', id = 'name', parents?) {
  // if list does not contain parents, you have to provide them
  if (parents) {
    for (const parent of parents) list.push({ name: parent })
  }
  const map = {}, roots = []
  for (let i = 0; i < list.length; i++) {
    const data = list[i]
    list[i] = {}
    list[i].data = data
    list[i].children = []; // initialize the children
    list[i].expanded = false;
    map[list[i].data[id]] = i; // initialize the map
  }
  for (let i = 0; i < list.length; i += 1) {
    const node = list[i]
    if (!map[node.data[parent]]) {
      roots.push(node)
    } else {
      list[map[node.data[parent]]].children.push(node)
    }
  }
  return roots;
}



